<template>
  <div class="mediaReportDetailChartMovie">
    <kendo-chart
      ref="chart"
      :legend-position="'top'"
      :series-defaults-type="'line'"
      :category-axis-categories="$parent.$data.categories"
      :category-axis-major-grid-lines-visible="false"
      :category-axis-labels-rotation="'auto'"
      :value-axis-labels-format="'{0}'"
      :value-axis-line-visible="false"
      :series-defaults-style="'smooth'"
      :value-axis-axis-crossing-value="'-10'"
      :tooltip-visible="true"
      :tooltip-format="'{0}'"
      :tooltip-template="$parent.$data.tooltiptemplate"
      :theme="'sass'"
    >
      <kendo-chart-series-item
        :data="$parent.$data.totalRuns"
        :line-style="'normal'"
        color="darkgray"
        name="Total Runs"
      />
      <kendo-chart-series-item
        :data="$parent.$data.totalGood"
        :line-style="'smooth'"
        color="green"
        name="Good"
      />
      <kendo-chart-series-item
        :data="$parent.$data.totalMedium"
        :line-style="'smooth'"
        color="orange"
        name="Medium"
      />
      <kendo-chart-series-item
        :data="$parent.$data.totalBad"
        :line-style="'smooth'"
        color="red"
        name="Bad"
      />
      <kendo-chart-series-item
        :data="$parent.$data.totalUnknown"
        :line-style="'smooth'"
        color="lightgray"
        name="Unknown"
      />
      <kendo-chart-series-item
        :data="$parent.$data.totalRaceStarted"
        :line-style="'smooth'"
        color="deeppink"
        name="Race Started"
        :visible="false"
      />
      <kendo-chart-series-item
        :data="$parent.$data.totalRaceFinished"
        :line-style="'smooth'"
        color="purple"
        name="Race Finished"
        :visible="false"
      />
      <kendo-chart-series-item
        :data="$parent.$data.totalObjectCaught"
        :line-style="'smooth'"
        color="yellow"
        name="Object Caught"
        :visible="false"
      />
      <kendo-chart-series-item
        :data="$parent.$data.totalObjectLost"
        :line-style="'smooth'"
        color="brown"
        name="Object Lost"
        :visible="false"
      />
      <kendo-chart-series-item
        :data="$parent.$data.totalTimeout"
        :line-style="'smooth'"
        color="cyan"
        name="Timeout"
        :visible="false"
      />
      <kendo-chart-series-item
        :data="$parent.$data.totalIsFaulty"
        :line-style="'smooth'"
        color="darkred"
        name="Is Faulty"
        :visible="false"
      />
      <kendo-chart-series-item
        :data="$parent.$data.totalTimingMode"
        :line-style="'smooth'"
        color="darkblue"
        name="Timing Mode"
      />
      <kendo-chart-series-item
        :data="$parent.$data.totalVirtualStopwatch"
        :line-style="'smooth'"
        color="darkkhaki"
        name="Virtual Stopwatch"
      />
    </kendo-chart>
  </div>
</template>

<script>
import { responsiveKendoGridMixin } from '@/mixins/responsiveKendoGridMixin.js';

export default {
  name: 'MediaReportDetailChartMovie',
  mixins: [
    responsiveKendoGridMixin
  ],
  created () {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize () {
      this.responsiveKendoGridMixin_refresh(this.$refs.chart);
    }
  }
}
</script>

