export const responsiveKendoGridMixin = {
  computed: {
    responsiveKendoGridMixin_responsiveInfo () {
      return $(window).width() > 1080;
    },
    responsiveKendoGridMixin_responsiveButtonCount () {
      if ($(window).width() > 1080) {
        return 5;
      }
      return 3;
    },
    responsiveKendoGridMixin_responsivePageSizes () {
      if ($(window).width() > 1080) {
        return [10, 20, 50, 100, 200];
      }
      return false;
    },
  },
  methods: {
    responsiveKendoGridMixin_responsivePageSize (minimum, maximum) {
      if ($(window).width() > 1080) {
        return maximum;
      }
      return minimum;
    },
    responsiveKendoGridMixin_refresh (ref) {
      if (ref == null) {
        return;
      }
      if (ref.kendoWidget == null) {
        return;
      }
      ref.kendoWidget().refresh();
    },
  }
}